import { FC, useEffect } from 'react'
import {
  Typography,
  TableRow,
  Table,
  TableCell,
  TableHead,
  TableBody,
  styled,
} from '@mui/material'
import { useApplicationById } from '../../hooks'
import { useIntl } from 'react-intl'
import { FormattedDateTime } from '../../components/FormattedDateTime'

const checkboxStyles = {
  width: '15px',
  height: '15px',
  padding: '5px',
  marginRight: '8px',
}
// @ts-ignore
const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  cursor: 'default',
  backgroundColor: 'transparent !important',
  borderBottomWidth: '1px !important',
  borderBottomStyle: 'solid !important',
  borderBottomColor: '#eee !important',
}))
const ChannelCheckBox = (props: any) => {
  const intl = useIntl()
  const { channel, handleChannelSelect, connectionDetails, menuId } = props
  const application = useApplicationById(channel.applicationId)
  const connection = connectionDetails.find(
    (item: any) => item.applicationId === channel.applicationId
  )
  const isSyncedTime = connection.lastMenuSyncedAt ? true : false
  return (
    <TableRow>
      <label style={{ display: 'contents' }}>
        <TableCell
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <input
            style={{ ...checkboxStyles }}
            type="checkbox"
            checked={channel.selected}
            onChange={() => {
              handleChannelSelect(channel.applicationId)
            }}
          />
          {application?.data?.data?.name}
        </TableCell>
      </label>
      <TableCell
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {isSyncedTime ? (
          connection.lastMenuSyncId === menuId ? (
            <FormattedDateTime value={connection.lastMenuSyncedAt} />
          ) : (
            <Typography>
              {`${intl.formatMessage({ id: 'status_unpublished' })}`}
            </Typography>
          )
        ) : (
          <Typography>
            {`${intl.formatMessage({ id: 'status_never' })}`}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  )
}

export interface LocationCheckBoxProps {
  handleLocationSelect: any
  groupedData: any
  onConnectionSelect: any
  setGroupedData: any
  mergedArray: any
}

export const LocationCheckBox: FC<LocationCheckBoxProps> = (props) => {
  const {
    handleLocationSelect,
    groupedData,
    onConnectionSelect,
    setGroupedData,
    mergedArray,
  } = props
  useEffect(() => {
    setGroupedData(mergedArray)
  }, [])
  useEffect(() => {
    if (groupedData?.channels?.length === 1) {
      const updatedData = { ...groupedData }
      updatedData.channels[0].selected = true
      onConnectionSelect(updatedData)
      setGroupedData(updatedData)
    }
  }, [groupedData?.channels])
  //Handle the selected Channels
  const handleChannelSelect = (id: any) => {
    const updatedData = { ...groupedData }
    updatedData.channels?.filter((item: any) => {
      if (item.applicationId === id) {
        item.selected = !item.selected
      }
    })
    onConnectionSelect(updatedData)
    setGroupedData(updatedData)
  }
  {
    return (
      <>
        {groupedData && (
          <ListComponent
            data={groupedData}
            handleChannelSelect={handleChannelSelect}
            handleLocationSelect={handleLocationSelect}
          />
        )}
      </>
    )
  }
}
const ListComponent = (props: any) => {
  const { data, handleLocationSelect, handleChannelSelect } = props
  const intl = useIntl()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <label
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <input
                style={{ ...checkboxStyles }}
                type="checkbox"
                checked={data.selected}
                onChange={() => {
                  handleLocationSelect()
                }}
              />
              {data.locationName}
            </label>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ py: '0 !important', cursor: 'default' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {intl.formatMessage({ id: 'label_channel_name' })}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '250px' }}>
                    {intl.formatMessage({ id: 'label_last_synced' })}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.channels?.map((channel: any) => (
                  <ChannelCheckBox
                    key={channel.applicationId}
                    channel={channel}
                    handleChannelSelect={handleChannelSelect}
                    connectionDetails={data.connectionDetails}
                    menuId={data.menuId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
