import { FC, useEffect, useState } from 'react'
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  styled,
} from '@mui/material'
import {
  ApplicationCard,
  AppLoader,
  AppWarningDialog,
} from '../../../components'
import {
  useApplication,
  useDeleteApplicationConnectionById,
} from '../../../hooks/useApplication'
import { useIntl } from 'react-intl'
import { Add } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

export interface ApplicationsViewProps {}
export interface AppDataProps {
  data: any
}
/**
 * Application name which is coming soon
 */
const commingSoonAppName = ['Cloud printer', 'Lightspeed POS (K-Series)']

/**
 * Applications
 */
export const ApplicationsPage: FC<ApplicationsViewProps> = () => {
  const intl = useIntl()
  const { locationId } = useParams()
  const applications = useApplication(locationId!)
  const appDeleteMutation = useDeleteApplicationConnectionById()
  const [connectApp, setConnectApp] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedApp, setSelectedApp] = useState({} as AppDataProps)
  const [applicationsData, setApplicationsData] = useState([] as any)
  useEffect(() => {
    if (applications?.data?.data) {
      let appData: any[] = []
      applications.data.data.map((item: any) => {
        const data = {
          ...item,
          isVisible: true,
        }
        appData.push(data)
      })
      setApplicationsData(appData)
    }
  }, [applications.data])

  // This function is used to set the visibility of the application instentlly in UI
  const setVisibilityHandler = (app: any) => {
    const index = applicationsData.findIndex(
      (item: any) => item.id === app.data.id
    )
    if (index !== -1) {
      applicationsData[index].isVisible = !applicationsData[index].isVisible
    }
  }
  const handleDeleteApp = (app: any) => {
    setShowConfirmationDialog(true)
    setSelectedApp({ data: { ...app } })
  }
  const handleDeleteConfirmed = () => {
    setShowConfirmationDialog(false)
    setVisibilityHandler(selectedApp)
    appDeleteMutation.mutate(
      {
        locationId: locationId!,
        appId: selectedApp.data.id,
      },
      {
        onError: () => {
          setVisibilityHandler(selectedApp)
        },
      }
    )
  }
  const handleDeleteCancel = () => {
    setShowConfirmationDialog(false)
  }
  /**
   * Show Loader
   */
  if (applications.isLoading) {
    return <AppLoader />
  }
  if (applications.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  const isAnyAppDisable = applicationsData.some((item: any) => !item.isEnabled)
  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    borderStyle: 'dashed',
    borderColor: '#ccc',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    minHeight: '150px',
  }))
  const StyledAddIcon = styled(Add)(() => ({
    fontSize: '56px',
    color: '#aaa',
  }))
  return (
    applicationsData && (
      <Box sx={{ my: 3 }}>
        <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }} sx={{ px: 3 }}>
          {applicationsData &&
            applicationsData?.map(
              (app: any) =>
                app.isEnabled &&
                app.isVisible && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={4}
                    sx={{ display: 'flex', position: 'relative' }}
                    key={app.id}
                  >
                    <ApplicationCard
                      app={app}
                      locationId={locationId!}
                      isDelete={true}
                      handleDeleteApp={handleDeleteApp}
                    />
                  </Grid>
                )
            )}
          {showConfirmationDialog && (
            <AppWarningDialog
              name={selectedApp?.data?.name}
              source="Applications"
              open={showConfirmationDialog}
              onConfirm={handleDeleteConfirmed}
              onCancel={handleDeleteCancel}
            />
          )}
          {isAnyAppDisable && (
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex' }}>
              <StyledCard onClick={() => setConnectApp(true)}>
                <StyledAddIcon />
              </StyledCard>
            </Grid>
          )}
          {connectApp && (
            <ApplicationsViewDialog
              data={applicationsData}
              selectedLocationId={locationId}
              setConnectApp={setConnectApp}
            />
          )}
        </Grid>
      </Box>
    )
  )
}
export interface ApplicationsViewDialogProps {
  data: any
  selectedLocationId: any
  setConnectApp: any
}

export const ApplicationsViewDialog: FC<ApplicationsViewDialogProps> = (
  props
) => {
  const intl = useIntl()
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="lg"
      sx={{ borderRadius: 2 }}
      onClose={() => props.setConnectApp(false)}
    >
      <DialogTitle>{intl.formatMessage({ id: 'action_connect' })}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
          sx={{ px: { sm: 3, xs: 1 } }}
        >
          {props.data.map(
            (app: any) =>
              !app.isEnabled && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    my: { sm: 0, xs: 1 },
                  }}
                  key={app.id}
                >
                  <ApplicationCard
                    app={app}
                    locationId={props.selectedLocationId}
                    setConnectApp={props.setConnectApp}
                    disabled={
                      commingSoonAppName.includes(app.name) ? true : false
                    }
                  />
                </Grid>
              )
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
