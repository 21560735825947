import { useIntl } from 'react-intl'
import { Box, Button, Grid, Typography, Checkbox } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
  MenuModifierCreateEntity,
  MenuModifierPatchEntity,
} from '../../../../../../../types'
import {
  useMenuModifierById,
  useMenuModifierUpdate,
} from '../../../../../../../hooks'
import {
  CommonTextField,
  StyledTablePaperWrapper,
  AppLoader,
  CommonPriceInputField,
} from '../../../../../../../components'
import { getCurrencySymbol } from '../../../../../../util'
import {
  defaultMenuModifierState,
  extractMenuModifierPatchPayload,
  validateMenuModifierEntity,
} from '../../../utils'

export function MenuModifierUpsert() {
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const { locationId, menuId, modifierId } = useParams()
  const intl = useIntl()
  // const locationsList = useLocations()
  //   .data?.pages?.map((value) => value.data)
  //   .flat()
  // const locationName = findNameById(locationId, locationsList)
  // let navigationPages: any = [
  //   { label: 'Locations', path: '/app/locations' },
  //   { label: locationName, path: `/app/locations/${locationId}/analytics` },
  //   {
  //     label: 'Catalog',
  //     path: `/app/locations/${locationId}/catalog/categories`,
  //   },
  //   {
  //     label: 'Modifiers',
  //     path: `/app/locations/${locationId}/catalog/modifiers`,
  //   },
  // ]

  const [modifierState, setMenuModifierState] = useState<
    MenuModifierCreateEntity | MenuModifierPatchEntity
  >(defaultMenuModifierState)
  const isValid = useMemo(
    () => validateMenuModifierEntity(modifierState),
    [modifierState]
  )
  const modifierUpdate = useMenuModifierUpdate(
    locationId!,
    menuId!,
    modifierId!
  )

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMenuModifierState((state: any) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const modifier = useMenuModifierById(locationId!, menuId!, modifierId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.data) {
        let existingData = data.data
        existingData['price'] = data.data?.price! / 100
        setMenuModifierState(existingData)
      }
    },
  })

  const handlePriceChange = (data: any) => {
    setMenuModifierState((state) => ({
      ...state,
      price: data,
    }))
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMenuModifierState((state: any) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleShowOnlineChange = (event: any) => {
    setMenuModifierState((state) => ({
      ...state,
      showOnline: event.target.checked,
    }))
  }
  const handleSubmitChange = async () => {
    modifierUpdate.mutate(extractMenuModifierPatchPayload(modifierState))
  }

  if (modifier.isLoading || modifier.isFetching || modifierUpdate.isLoading) {
    return <AppLoader />
  }

  if (modifier.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  if (modifierUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/menu/${menuId}/modifiers`}
        replace
      />
    )
  }

  return (
    <>
      {/* <PageNavigationView navigationPages={navigationPages} /> */}
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={9}>
              <Box marginTop={1}>
                <Typography variant="h6" component="h1">
                  {modifierId
                    ? `${intl.formatMessage({ id: 'action_update' })}`
                    : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                  {intl.formatMessage({ id: 'label_modifier' })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="inherit"
                onClick={() =>
                  navigate(
                    `/app/locations/${locationId}/menu/${menuId}/modifiers`
                  )
                }
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmitChange}
                disabled={!isValid}
                color="success"
                sx={{ color: 'white', ml: 2 }}
              >
                {modifierId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} md={12} sm={12} lg={10}>
              <CommonTextField
                id="outlined-basic"
                label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
                autoFocus={true}
                value={modifierState.name || ''}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item xs={2} md={12} sm={12} lg={2}>
              <Checkbox
                checked={modifierState.showOnline!}
                onChange={handleShowOnlineChange}
              />
              showOnline
            </Grid>
            <Grid item mt={{ xs: 2, sm: 0 }} xs={12} sm={6}>
              <CommonPriceInputField
                label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
                currency={currency}
                value={modifierState.price!}
                onChange={handlePriceChange}
              />
            </Grid>
            <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
              <CommonTextField
                id="outlined-multiline-static"
                label={intl.formatMessage({ id: 'label_description' })}
                onChange={handleDescriptionChange}
                value={modifierState.description || ''}
                rows={4}
                multiline
              />
            </Grid>
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
