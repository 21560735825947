import {
  useState,
  useMemo,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
} from 'react'
import { Tabs, Tab, Typography, Box, Grid, Button, styled } from '@mui/material'
import { CommonTextField, CommonPriceInputField } from '../../../components'
import { useIntl } from 'react-intl'
import {
  defaultMenuModifierState,
  extractMenuModifierPatchPayload,
  validateMenuModifierEntity,
} from '../../Locations/CardSelect/Menu/utils'
import { useMenuModifierUpdate } from '../../../hooks'
import { MenuModifierPatchEntity } from '../../../types'
import { useParams } from 'react-router-dom'
import { getCurrencySymbol } from '../../util'
import { ShowOnlineCategoryView } from '../components/ShowOnlineview'

interface TabPanelProps {
  index: number
  data: any
  modifierId: string
  onModifierUpdate: any
  modifierState: MenuModifierPatchEntity
  setMenuModifierState: React.Dispatch<
    React.SetStateAction<MenuModifierPatchEntity>
  >
  isValid: any
}

function TabPanel(props: TabPanelProps) {
  const intl = useIntl()
  const {
    data,
    index,
    modifierId,
    onModifierUpdate,
    modifierState,
    setMenuModifierState,
    isValid,
  } = props
  const currency = getCurrencySymbol()
  const { locationId, menuId } = useParams()
  const [initialData, setInitialData] = useState<MenuModifierPatchEntity>(
    defaultMenuModifierState
  )
  useEffect(() => {
    const updatedData = { ...data, showOnline: data?.showOnline ?? false }
    setInitialData(updatedData)
    setMenuModifierState(updatedData)
  }, [data])
  const modifierUpdate = useMenuModifierUpdate(
    locationId!,
    menuId!,
    modifierId!
  )

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMenuModifierState((state: any) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const selectedImage = data?.imageUrl
    ? `url(${data?.imageUrl})`
    : `url(https://placehold.co/600x400)`
  const StyledImageBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: selectedImage,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }))

  const handlePriceChange = (data: any) => {
    setMenuModifierState((state: any) => ({
      ...state,
      price: data,
    }))
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMenuModifierState((state: any) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleShowOnline = (showOnline: boolean, modId: string) => {
    setMenuModifierState((state: any) => ({
      ...state,
      showOnline: showOnline,
    }))
  }
  const handleSubmitChange = async () => {
    const updatedModifier = { ...modifierState }
    modifierUpdate.mutate(extractMenuModifierPatchPayload(updatedModifier))
    if (onModifierUpdate) {
      onModifierUpdate(updatedModifier)
    }
  }

  return (
    <Box sx={{ paddingLeft: 2 }} key={index}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={9}>
          <Box marginTop={1}>
            <Typography variant="h6" component="h1">
              {intl.formatMessage({ id: 'action_update' })}{' '}
              {intl.formatMessage({ id: 'label_modifier' })}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          justifyContent="flex-end"
          display="flex"
        >
          <Button
            variant="contained"
            onClick={handleSubmitChange}
            disabled={!isValid}
            color="success"
            sx={{ color: 'white', ml: 2 }}
          >
            {intl.formatMessage({ id: 'action_update' })}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={12}>
          <ShowOnlineCategoryView
            data={data}
            handleShowOnline={handleShowOnline}
            isLabel={true}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid item xs={12} mt={{ xs: 1.5, sm: 1.5 }}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              autoFocus={true}
              value={modifierState?.name || ''}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item mt={{ xs: 2, sm: 2 }} xs={12}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={modifierState?.price! || 0}
              onChange={handlePriceChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledImageBox />
        </Grid>
        <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
          <CommonTextField
            id="outlined-multiline-static"
            label={intl.formatMessage({ id: 'label_description' })}
            onChange={handleDescriptionChange}
            value={modifierState?.description || ''}
            rows={2}
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export const ModifierTabs = (props: any) => {
  const { modifiers, onModifierUpdate } = props
  const [value, setValue] = useState(0)
  const [modifierState, setMenuModifierState] =
    useState<MenuModifierPatchEntity>(defaultMenuModifierState)

  const sortedData = modifiers?.sort(
    (a: any, b: any) => a.position - b.position
  )

  useEffect(() => {
    if (sortedData.length > 0) {
      setMenuModifierState(sortedData[value]) // Update state based on selected tab
    }
  }, [value, sortedData]) // Run effect when value or sortedData changes

  const isModified = useMemo(() => {
    return JSON.stringify(modifierState) !== JSON.stringify(sortedData[value])
  }, [modifierState, sortedData, value])

  const isValid = useMemo(
    () => validateMenuModifierEntity(modifierState) && isModified,
    [modifierState, isModified]
  )
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ display: 'block', width: '100%' }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          py: 2,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: '25%' }}
        >
          {sortedData.map((modifier: any, index: number) => (
            <Tab
              key={modifier.id}
              label={modifier.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {/**
         * tabpanel should render if I choose a tab
         */}
        {sortedData.length > 0 && (
          <TabPanel
            data={sortedData[value]}
            index={value}
            modifierId={sortedData[value]?.id}
            onModifierUpdate={onModifierUpdate}
            modifierState={modifierState}
            setMenuModifierState={setMenuModifierState}
            isValid={isValid}
          />
        )}
      </Box>
    </Box>
  )
}
