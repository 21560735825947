import { useEffect, useState, ChangeEvent } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'
import {
  defaultItemPatchState,
  validationCheckUpdateforMenuItem,
} from '../../Locations/CardSelect/Menu/utils'
import { MenuItemCreateEntity, MenuItemPatchEntity } from '../../../types'
import {
  CommonPriceInputField,
  CommonTextField,
  ServiceAvailabilityInput,
} from '../../../components'
import {
  useCategory,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroups,
  useMenuModifiers,
  useProducts,
} from '../../../hooks'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { getCurrencySymbol } from '../../util'
import { ShowOnlineCategoryView } from '../components/ShowOnlineview'
import { RenderModifierGroupsView } from './EditModifierGroupView'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const fulfillmentTypes: any = ['DELIVERY', 'PICKUP', 'DINE-IN']
/**
 * Dialog for editing an item in the menu.
 */
export function EditItemMenuV2() {
  const intl = useIntl()
  const navigate = useNavigate()
  const { locationId, menuId, itemId } = useParams()
  const currency = getCurrencySymbol()
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (
        (data?.data?.fulfillmentTypes && data.data.fulfillmentTypes?.length) ||
        (data?.data?.serviceAvailability &&
          data.data.serviceAvailability?.length)
      ) {
        setItemSpecificAvailability(true)
      }
    },
  })?.data?.data
  const itemUpdateMutation = useItemUpdate(locationId!, menuId!, itemId!)
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const [menuItemState, setMenuItemState] = useState<
    MenuItemCreateEntity | MenuItemPatchEntity
  >(defaultItemPatchState)
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const item = data.pages.map((page: any) => page.data).flat()
      fetchItemData(item)
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })
  const [catalogProductData, setCatalogProductData] = useState<any>(null)
  const fetchItemData = (catalogProducts: any) => {
    const item = catalogProducts?.find(
      (item: any) => item.id === itemData?.catalogProductId
    )
    setCatalogProductData(item)
  }
  useEffect(() => {
    catalogProducts?.data &&
      fetchItemData(
        catalogProducts?.data?.pages.map((page: any) => page.data).flat()
      )
    if (itemData) {
      setMenuItemState({
        name: itemData.name,
        description: itemData.description,
        price: itemData.price / 100,
        showOnline: itemData.showOnline,
        fulfillmentTypes: itemData?.fulfillmentTypes?.length
          ? itemData.fulfillmentTypes
          : menuData.data?.data?.fulfillmentTypes,
        categories: itemData.categories ? itemData.categories : [],
        modifierGroups: itemData.modifierGroups ? itemData.modifierGroups : [],
        serviceAvailability: itemData?.serviceAvailability?.length
          ? itemData.serviceAvailability
          : menuData.data?.data?.serviceAvailability,
      })
    }
  }, [itemData, menuData.data?.data])
  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })
  const categoriesOptions = categories.data?.pages
    .map((value) => value.data)
    .flat()
    ?.map((record) => record)
  const selectedCategoryNames = (menuItemState.categories || [])
    .map((categoryId: any) => {
      const selectedModifierGroup = categoriesOptions?.find(
        (option: any) => option.id === categoryId
      )
      return selectedModifierGroup ? selectedModifierGroup.name : ''
    })
    .join(', ')
  const modifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
    },
  })
  const [updatedState, setUpdatedState] = useState<MenuItemPatchEntity>(
    {} as any
  )
  const [selectedModifierGroupNames, setSelectedModifierGroupNames] = useState<
    string | undefined
  >(undefined)
  const [valid, setValid] = useState(false)
  const [itemSpecificAvailability, setItemSpecificAvailability] =
    useState<boolean>(false)
  const modifierGroupsData = modifierGroups?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const modifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifiers.fetchNextPage()
      }
    },
  })
  const menuModifiersData = modifiers?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const modifierGroupOptions = modifierGroupsData?.filter((group: any) =>
    catalogProductData?.modifierGroups?.includes(group.catalogModifierGroupId)
  )

  const selectedModifierGroup = (menuItemState.modifierGroups || [])?.map(
    (modifierGroupId: any) => {
      const selectedCategory = modifierGroupOptions?.find(
        (option: any) => option.id === modifierGroupId
      )
      const modifiers: any = selectedCategory?.modifiers?.map((modifier: any) =>
        menuModifiersData?.find(
          (menuModifier: any) => menuModifier.id === modifier
        )
      )
      return {
        ...selectedCategory,
        modifiers: modifiers?.map((mod: any) => ({
          id: mod?.id,
          name: mod?.name,
          price: mod?.price / 100,
          description: mod?.description,
          showOnline: mod?.showOnline,
          imageUrl: mod?.imageUrl,
          position: mod?.position,
        })),
      }
    }
  )
  useEffect(() => {
    if (menuItemState.modifierGroups) {
      const modifierGroups = menuItemState.modifierGroups
        .map((modifierGroupId: any) => {
          const selectedCategory = modifierGroupOptions?.find(
            (option: any) => option.id === modifierGroupId
          )
          return selectedCategory ? selectedCategory.name : ''
        })
        .join(', ')
      setSelectedModifierGroupNames(modifierGroups)
    }
  }, [menuItemState, modifierGroupOptions])
  function validationCheckUpdate(object: any) {
    const validationResults = validationCheckUpdateforMenuItem(object)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate(newObject)
  }
  const handleShowOnlineChange = (showOnline: boolean, itemId: string) => {
    updatedState.showOnline = showOnline
    validateAndSet(updatedState)
  }
  const handleNameChange = (event: any) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handleDescriptionChange = (event: any) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleFullFillmentType = (event: any, value: any) => {
    updatedState.fulfillmentTypes = value
    validateAndSet(updatedState)
  }
  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }
  const handleCategoriesChange = (_: any, newValues: any) => {
    updatedState.categories = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const handleModifierGroupChange = (_: any, newValues: any) => {
    updatedState.modifierGroups = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const onAvailabilityChange = (availability: any) => {
    updatedState.serviceAvailability = availability
    validateAndSet(updatedState)
  }
  const handleCheckBoxChanges = (event: ChangeEvent<HTMLInputElement>) => {
    setItemSpecificAvailability(event.target.checked)
    if (event.target.checked) {
      updatedState.serviceAvailability = menuItemState.serviceAvailability
      updatedState.fulfillmentTypes = menuItemState.fulfillmentTypes
      validateAndSet(updatedState)
    } else {
      updatedState.serviceAvailability = []
      updatedState.fulfillmentTypes = []
      validateAndSet(updatedState)
    }
  }
  const handleSave = () => {
    if (updatedState.price) {
      // Convert price from string to number
      const updatedStateWithNumberPrice = {
        ...updatedState,
        price: parseFloat(updatedState.price) * 100,
      }
      itemUpdateMutation.mutate(updatedStateWithNumberPrice, {
        onSuccess: () => {
          toast.success('Item Updated')
          navigate(`/app/locations/${locationId}/menus/${menuId}`)
        },
      })
    } else {
      itemUpdateMutation.mutate(updatedState, {
        onSuccess: () => {
          toast.success('Item Updated')
          navigate(`/app/locations/${locationId}/menus/${menuId}`)
        },
      })
    }
  }
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage({ id: 'action_edit_item' })}
        </Typography>
        <Box>
          <Button
            onClick={() =>
              navigate(`/app/locations/${locationId}/menus/${menuId}`)
            }
            sx={{ mr: 1 }}
          >
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleSave()}
            disabled={!valid}
            color="success"
            variant="contained"
            sx={{ color: '#fff' }}
          >
            {intl.formatMessage({ id: 'action_update' })}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {/* Form to get the name and description of the cateogry */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} mt={1}>
            <ShowOnlineCategoryView
              handleShowOnline={handleShowOnlineChange}
              data={updatedState?.showOnline ? updatedState : menuItemState}
              isLabel={true}
            />
          </Grid>
          {/* Name */}
          <Grid item xs={12} md={6}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? menuItemState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={updatedState?.price ?? menuItemState.price!}
              onChange={handlePriceChange}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={modifierGroupOptions ? modifierGroupOptions : []}
              value={
                modifierGroupOptions?.filter((option: any) =>
                  updatedState?.modifierGroups
                    ? updatedState.modifierGroups.includes(option.id)
                    : menuItemState.modifierGroups?.includes(option.id)
                ) || []
              }
              onChange={handleModifierGroupChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_modifier_group',
                  })}`}
                  variant="outlined"
                  value={selectedModifierGroupNames}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={categoriesOptions ? categoriesOptions : []}
              value={
                categoriesOptions?.filter((option: any) =>
                  updatedState.categories
                    ? updatedState.categories.includes(option.id)
                    : menuItemState.categories?.includes(option.id)
                ) || []
              }
              onChange={handleCategoriesChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_categories',
                  })}`}
                  variant="outlined"
                  value={selectedCategoryNames || ''}
                />
              )}
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12} md={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_description',
              })}`}
              onChange={handleDescriptionChange}
              value={updatedState?.description ?? menuItemState?.description}
              rows={2}
              multiline
            />
          </Grid>
          {updatedState?.modifierGroups
            ? updatedState?.modifierGroups?.length !== 0 && (
                <RenderModifierGroupsView
                  menuItemState={updatedState}
                  selectedModifierGroup={selectedModifierGroup}
                  locationId={locationId}
                  menuId={menuId}
                />
              )
            : menuItemState?.modifierGroups?.length !== 0 && (
                <RenderModifierGroupsView
                  menuItemState={menuItemState}
                  selectedModifierGroup={selectedModifierGroup}
                  locationId={locationId}
                  menuId={menuId}
                />
              )}
          <Grid item xs={12}>
            <Typography component={'div'}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckBoxChanges}
                    checked={itemSpecificAvailability as boolean}
                  />
                }
                label="Item Specific Availability"
              />
            </Typography>
          </Grid>
          {itemSpecificAvailability ? (
            <>
              {/* fulfilment */}
              <Grid item xs={12} md={5}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="checkboxes-tags-demos"
                  options={fulfillmentTypes ? fulfillmentTypes : []}
                  onChange={handleFullFillmentType}
                  value={
                    fulfillmentTypes?.filter((option: any) =>
                      updatedState?.fulfillmentTypes
                        ? updatedState.fulfillmentTypes.includes(option)
                        : menuItemState.fulfillmentTypes?.includes(option)
                    ) || []
                  }
                  disableCloseOnSelect
                  renderOption={(props, optionScope, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {optionScope}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={
                        updatedState?.fulfillmentTypes
                          ? updatedState.fulfillmentTypes?.join(', ')
                          : menuItemState?.fulfillmentTypes?.join(', ')
                      }
                      label={`${intl.formatMessage({
                        id: 'label_item',
                      })}${' '}${intl.formatMessage({
                        id: 'label_fulfilment_type',
                      })}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8} sx={{ pt: 0 }}>
                <ServiceAvailabilityInput
                  availabilities={
                    updatedState?.serviceAvailability ??
                    menuItemState.serviceAvailability
                  }
                  onChange={onAvailabilityChange}
                  isMandatory={false}
                  fullWidth={true}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </Box>
  )
}
