import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useAccessToken } from './useAccessToken'
import { useAuthStore } from './useAuthStore'
import { signalToCancellationToken } from './utils'
import {
  CatalogModifierGroupCreateEntity,
  CatalogModifierGroupPatchEntity,
  ErrorResponse,
  GetCatalogModifierGroupByIdResponse,
  ListCatalogModifierGroupResponse,
} from '../types'
import { api } from '../services/api'
import { toast } from 'react-toastify'

export interface UseModifierGroupByIdProps<
  TData = GetCatalogModifierGroupByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useModifierGroups = (locationId: string) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListCatalogModifierGroupResponse>(
    ['accounts', accountId, 'locations', locationId, 'modifier-groups'],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifier-groups`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}

export const useModifierGroupCreate = (locationId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogModifierGroupByIdResponse,
    ErrorResponse,
    CatalogModifierGroupCreateEntity
  >(
    (modifierGroup) =>
      api
        .post<GetCatalogModifierGroupByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifier-groups`,
          modifierGroup,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'modifier-groups',
          ],
          exact: true,
        })
        toast.success('Modifier Group Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useModifierGroupUpdate = (
  locationId: string,
  modifierGroupId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useMutation<
    GetCatalogModifierGroupByIdResponse,
    ErrorResponse,
    CatalogModifierGroupPatchEntity
  >(
    (modifierGroup) =>
      api
        .patch<GetCatalogModifierGroupByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifier-groups/${modifierGroupId}`,
          modifierGroup,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'modifier-groups',
          ],
          exact: true,
        })
        toast.success('Modifier Group Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useModifierGroupById = (
  locationId?: string,
  modifierGroupId?: string,
  props?: UseModifierGroupByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetCatalogModifierGroupByIdResponse, ErrorResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'modifier-group',
      modifierGroupId,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifier-groups/${modifierGroupId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled:
        !!token?.access_token &&
        !!accountId &&
        !!locationId &&
        !!modifierGroupId,
      ...props,
    }
  )
}

export const useModifierGroupDelete = (locationId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (modifierGroupId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifier-groups/${modifierGroupId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'modifier-groups',
        ])
        toast.success('Modifier Group Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
