import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  getLocationQueryById,
  useCategory,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroups,
  useProducts,
} from '../../../../../../../hooks'
import {
  AppLoader,
  CommonTextField,
  StyledTablePaperWrapper,
  ServiceAvailabilityInput,
  PageNavigationView,
  CommonPriceInputField,
} from '../../../../../../../components'
import { MenuItemPatchEntity } from '../../../../../../../types'
import { useIntl } from 'react-intl'
import {
  defaultItemPatchState,
  validationCheckUpdateforMenuItem,
} from '../../../utils'
import { getCurrencySymbol } from '../../../../../../util'
import { toast } from 'react-toastify'

const fulfillmentTypes = ['DELIVERY', 'PICKUP', 'DINE-IN']

export function MenuItemUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const currency = getCurrencySymbol()
  const { locationId, menuId, itemId } = useParams()
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  // fetch Menu data
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  // fetch Catalog Products Data
  const fetchItemData = (catalogProducts: any) => {
    const item = catalogProducts?.find(
      (item: any) => item.id === itemData?.catalogProductId
    )
    setCatalogProductData(item)
  }
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const item = data.pages.map((page: any) => page.data).flat()
      fetchItemData(item)
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })
  const itemUpdate = useItemUpdate(locationId!, menuId!, itemId!)
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (
        (data?.data?.fulfillmentTypes && data.data.fulfillmentTypes?.length) ||
        (data?.data?.serviceAvailability &&
          data.data.serviceAvailability?.length)
      ) {
        setItemSpecificAvailability(true)
      }
    },
  })?.data?.data

  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })
  const modifierGroups = useMenuModifierGroups(locationId!, menuId!)
  const modifierGroupsData = modifierGroups?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const [menuItemState, setMenuItemState] = useState<MenuItemPatchEntity>(
    defaultItemPatchState
  )
  const [updatedState, setUpdatedState] = useState<MenuItemPatchEntity>({})
  const [catalogProductData, setCatalogProductData] = useState<any>(undefined)
  const [valid, setValid] = useState(false)
  const [selectedModifierGroupNames, setSelectedModifierGroupNames] = useState<
    string | undefined
  >(undefined)
  const modifierGroupOptions = modifierGroupsData?.filter((group: any) =>
    catalogProductData?.modifierGroups?.includes(group.catalogModifierGroupId)
  )
  const categoriesOptions = categories.data?.pages
    .map((value) => value.data)
    .flat()
    ?.map((record) => record)

  const selectedCategoryNames = (menuItemState.categories || [])
    .map((categoryId: any) => {
      const selectedModifierGroup = categoriesOptions?.find(
        (option: any) => option.id === categoryId
      )
      return selectedModifierGroup ? selectedModifierGroup.name : ''
    })
    .join(', ')

  //used to check if the item has specific availability
  const [itemSpecificAvailability, setItemSpecificAvailability] =
    useState(false)
  const menuName = menuData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    { label: 'Items', path: `` },
  ]

  useEffect(() => {
    catalogProducts?.data &&
      fetchItemData(
        catalogProducts?.data?.pages.map((page: any) => page.data).flat()
      )
    if (itemData) {
      setMenuItemState({
        name: itemData.name,
        description: itemData.description,
        price: itemData.price / 100,
        showOnline: itemData.showOnline,
        fulfillmentTypes: itemData?.fulfillmentTypes?.length
          ? itemData.fulfillmentTypes
          : menuData.data?.data?.fulfillmentTypes,
        categories: itemData.categories ? itemData.categories : [],
        modifierGroups: itemData.modifierGroups ? itemData.modifierGroups : [],
        serviceAvailability: itemData?.serviceAvailability?.length
          ? itemData.serviceAvailability
          : menuData.data?.data?.serviceAvailability,
      })
    }
  }, [itemData, menuData.data?.data])

  useEffect(() => {
    if (menuItemState.modifierGroups) {
      const modifierGroups = menuItemState.modifierGroups
        .map((modifierGroupId: any) => {
          const selectedCategory = modifierGroupOptions?.find(
            (option: any) => option.id === modifierGroupId
          )
          return selectedCategory ? selectedCategory.name : ''
        })
        .join(', ')
      setSelectedModifierGroupNames(modifierGroups)
    }
  }, [menuItemState, modifierGroupOptions])

  function validationCheckUpdate() {
    const validationResults = validationCheckUpdateforMenuItem(updatedState)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate()
  }

  const handleNameChange = (event: any) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }

  const handleShowOnlineChange = (event: any) => {
    updatedState.showOnline = event.target.checked
    validateAndSet(updatedState)
  }

  const handleDescriptionChange = (event: any) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }

  const handleFullFillmentType = (event: any, value: any) => {
    updatedState.fulfillmentTypes = value
    validateAndSet(updatedState)
  }

  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }

  const onAvailabilityChange = (availability: any) => {
    updatedState.serviceAvailability = availability
    validateAndSet(updatedState)
  }

  const handleModifierGroupChange = (_: any, newValues: any) => {
    updatedState.modifierGroups = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const handleCheckBoxChanges = (event: ChangeEvent<HTMLInputElement>) => {
    setItemSpecificAvailability(event.target.checked)
    if (event.target.checked) {
      updatedState.serviceAvailability = menuItemState.serviceAvailability
      updatedState.fulfillmentTypes = menuItemState.fulfillmentTypes
      validateAndSet(updatedState)
    } else {
      updatedState.serviceAvailability = []
      updatedState.fulfillmentTypes = []
      validateAndSet(updatedState)
    }
  }

  const handleCategoriesChange = (_: any, newValues: any) => {
    updatedState.categories = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }

  const handleSubmitChange = async () => {
    if (updatedState.price) {
      const updatedStateWithNumberPrice = {
        ...updatedState,
        price: parseFloat(updatedState.price) * 100,
      }
      itemUpdate.mutate(updatedStateWithNumberPrice, {
        onSuccess: () => {
          toast.success('Item Updated')
        },
      })
    } else {
      itemUpdate.mutate(updatedState, {
        onSuccess: () => {
          toast.success('Item Updated')
        },
      })
    }
  }

  if (itemUpdate.isLoading) {
    return <AppLoader />
  }

  if (itemUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/menu/${menuId}/items`}
        replace
      />
    )
  }

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={9}>
              <Box marginTop={1}>
                <Typography variant="h6" component="h1">
                  {itemId
                    ? `${intl.formatMessage({ id: 'action_update' })}`
                    : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                  {intl.formatMessage({ id: 'label_item' })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="inherit"
                onClick={() =>
                  navigate(`/app/locations/${locationId}/menu/${menuId}/items`)
                }
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
              <Button
                variant="contained"
                disabled={!valid}
                onClick={handleSubmitChange}
                color="success"
                sx={{ color: 'white', ml: 2 }}
              >
                {itemId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} md={6} sm={12} lg={6}>
              <CommonTextField
                id="outlined-basic"
                label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
                onChange={handleNameChange}
                value={updatedState?.name ?? menuItemState?.name}
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CommonPriceInputField
                label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
                currency={currency}
                value={updatedState.price! ?? menuItemState.price!}
                onChange={handlePriceChange}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12} lg={2}>
              <Checkbox
                checked={updatedState.showOnline ?? menuItemState.showOnline!}
                onChange={handleShowOnlineChange}
              />
              showOnline
            </Grid>
            <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
              <Autocomplete
                multiple
                limitTags={4}
                disableCloseOnSelect
                options={modifierGroupOptions ? modifierGroupOptions : []}
                value={
                  modifierGroupOptions?.filter((option: any) =>
                    updatedState?.modifierGroups
                      ? updatedState.modifierGroups.includes(option.id)
                      : menuItemState.modifierGroups?.includes(option.id)
                  ) || []
                }
                onChange={handleModifierGroupChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      control={<Checkbox checked={selected} />}
                      label={option.name}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${intl.formatMessage({
                      id: 'label_modifier_group',
                    })}`}
                    variant="outlined"
                    value={selectedModifierGroupNames || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
              <Autocomplete
                multiple
                limitTags={4}
                disableCloseOnSelect
                options={categoriesOptions ? categoriesOptions : []}
                value={
                  categoriesOptions?.filter((option: any) =>
                    updatedState.categories
                      ? updatedState.categories.includes(option.id)
                      : menuItemState.categories?.includes(option.id)
                  ) || []
                }
                onChange={handleCategoriesChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      control={<Checkbox checked={selected} />}
                      label={option.name}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${intl.formatMessage({
                      id: 'label_categories',
                    })}`}
                    variant="outlined"
                    value={selectedCategoryNames || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
              <CommonTextField
                id="outlined-multiline-static"
                label={`${intl.formatMessage({
                  id: 'label_description',
                })}`}
                onChange={handleDescriptionChange}
                value={updatedState.description ?? menuItemState.description}
                rows={4}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component={'div'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckBoxChanges}
                      checked={itemSpecificAvailability as boolean}
                    />
                  }
                  label="Item Specific Availability"
                />
              </Typography>
            </Grid>
            {itemSpecificAvailability ? (
              <>
                <Grid item xs={12} sm={6} marginTop={1}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags-demos"
                    options={fulfillmentTypes ? fulfillmentTypes : []}
                    onChange={handleFullFillmentType}
                    value={
                      fulfillmentTypes?.filter((option: any) =>
                        updatedState?.fulfillmentTypes
                          ? updatedState.fulfillmentTypes.includes(option)
                          : menuItemState.fulfillmentTypes?.includes(option)
                      ) || []
                    }
                    disableCloseOnSelect
                    renderOption={(props, optionScope, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {optionScope}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={
                          updatedState?.fulfillmentTypes
                            ? updatedState.fulfillmentTypes?.join(', ')
                            : menuItemState?.fulfillmentTypes?.join(', ')
                        }
                        label={`${intl.formatMessage({
                          id: 'label_fulfilment_type',
                        })}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ pt: 0 }}>
                  <ServiceAvailabilityInput
                    availabilities={
                      updatedState.serviceAvailability ??
                      menuItemState.serviceAvailability
                    }
                    onChange={onAvailabilityChange}
                    isMandatory={false}
                    title="Item Specific Availability"
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
