import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { FC } from 'react'
import {
  useAccountLocationConnectionPull,
  useAccountLocationConnections,
} from '../../hooks/useAccountLocationConnections'
import { AppLoader } from '../AppLoader'
import { toast } from 'react-toastify'

export interface SyncCatalogDialogProps extends DialogProps {
  applicationId: string
  locationId: string
}

/**
 * SyncCatalogDialog component
 */
export const SyncCatalogDialog: FC<SyncCatalogDialogProps> = (props) => {
  const pull = useAccountLocationConnectionPull()
  const connections = useAccountLocationConnections({
    locationId: props.locationId,
  })

  /**
   * Find the current connection id
   */
  const connection =
    connections.isSuccess &&
    connections.data?.find((c: any) => c.applicationId === props.applicationId)

  // Close Request Handler
  const handleDialogCloseRequest = (e: any) => {
    if (pull.isLoading) return
    props.onClose && props.onClose(e, 'backdropClick')
  }

  // Sync Handler
  const onSync = (e: any) => {
    if (connection) {
      pull.mutate(connection, {
        onError: (error) => {
          console.error(error)
        },
        onSuccess: () => {
          toast.success('Catalog Synced Successfully')
          handleDialogCloseRequest(e)
        },
      })
    }
  }

  return (
    <Dialog {...props} onClose={handleDialogCloseRequest}>
      <DialogTitle id="form-dialog-title">Sync Catalog</DialogTitle>
      <DialogContent>
        {connections.isLoading && <AppLoader />}
        <DialogContentText>
          This will sync the catalog from the POS system to the POS Hub, are you
          sure you want to sync?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleDialogCloseRequest}
          disabled={pull.isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSync}
          disabled={pull.isLoading || connections.isLoading}
        >
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  )
}
