import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import {
  AppLoader,
  NoRecordFound,
  ShowOnlineView,
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
  FormattedDateTime,
} from '../../../../../components'
import { useModifiers } from '../../../../../hooks'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { getCurrencySymbol, shortenText } from '../../../../util'

export function Modifiers() {
  const { locationId } = useParams()
  const intl = useIntl()
  const currency = getCurrencySymbol()

  const modifiers = useModifiers(locationId!)
  const records = modifiers.data?.pages?.map((value) => value.data).flat()
  const sortedRecords = records?.sort(
    (a: any, b: any) => a.position - b.position
  )
  const hasShowOnline = sortedRecords?.some(
    (modifier: any) => modifier?.showOnline
  )
  useEffect(() => {
    modifiers.hasNextPage && modifiers.fetchNextPage()
  }, [modifiers.data])
  if (modifiers.isLoading) {
    return <AppLoader />
  }
  if (modifiers.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  return (
    <>
      {modifiers.isSuccess && !sortedRecords?.length ? (
        <NoRecordFound title="Modifiers" />
      ) : (
        <StyledTablePaperWrapper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  {hasShowOnline ? (
                    <TableCell>
                      {intl.formatMessage({ id: 'label_show_online' })}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    {intl.formatMessage({ id: 'label_price' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_created_at' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRecords?.map((modifierData, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'default !important' }}
                    >
                      <TableCell>
                        {modifierData.name?.length > 25 ? (
                          <Tooltip title={modifierData.name}>
                            <Typography variant="body2">
                              {shortenText(modifierData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          modifierData.name
                        )}
                      </TableCell>
                      {hasShowOnline ? (
                        <TableCell>
                          <ShowOnlineView item={modifierData} disabled={true} />
                        </TableCell>
                      ) : null}
                      <TableCell>
                        {currency} {''}
                        {modifierData.price
                          ? (
                              parseFloat(modifierData.price.toString()) / 100
                            ).toFixed(2)
                          : '0.00'}
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={modifierData.createdAt} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {modifiers.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => modifiers.fetchNextPage()}
                disabled={modifiers.isFetching}
                variant="outlined"
                color="primary"
              >
                {modifiers.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
    </>
  )
}
