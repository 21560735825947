import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Table,
  Button,
  styled,
  IconButton,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material'
import { useState, useMemo, ChangeEvent, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { CommonTextField } from '../../../components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  useMenuModifierGroupUpdate,
  useMenuModifiers,
  useModifierGroupById,
} from '../../../hooks'
import { MenuModifierGroupPatchEntity } from '../../../types'
import {
  defaultMenuModifierGroupState,
  validateMenuModifierGroupEntity,
  extractMenuModifierGroupPatchPayload,
} from '../../Locations/CardSelect/Menu/utils'
import { ModifierTabs } from './EditModifers'
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-hover:hover': {
    backgroundColor: 'transparent',
  },
}))
export const RenderModifierGroupsView = (props: any) => {
  const { selectedModifierGroup, locationId, menuId } = props
  const intl = useIntl()
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null)
  const [modifierGroupId, setModifierGroupId] = useState<string | undefined>(
    undefined
  )
  const sortedModifierGroups = selectedModifierGroup?.sort(
    (a: any, b: any) => a.position - b.position
  )

  const [errorMessage, setErrorMessage] = useState('')

  const validateMinMaxValues = (min: any, max: any) => {
    min = min != -1 ? min : modifierGroupState.minPermitted
    max = max != -1 ? max : modifierGroupState.maxPermitted

    const isMinMaxExceptioned = min == 1 && max == 0 ? true : false

    if (min && max && min > max && !isMinMaxExceptioned) {
      setErrorMessage('Minimum cannot be greater than maximum')
    } else {
      setErrorMessage('')
    }
  }

  const [modifierGroupState, setModifierGroupState] =
    useState<MenuModifierGroupPatchEntity>(defaultMenuModifierGroupState)
  const isValid = useMemo(
    () => validateMenuModifierGroupEntity(modifierGroupState),
    [modifierGroupState]
  )

  const modifierGroupUpdate = useMenuModifierGroupUpdate(
    locationId!,
    menuId!,
    modifierGroupId!
  )

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        minPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
      validateMinMaxValues(inputValue, -1)
    }
  }
  const handleMaximumChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        maxPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
      validateMinMaxValues(-1, inputValue)
    }
  }

  const handleModifierGroupUpdate = async () => {
    modifierGroupUpdate.mutate(
      extractMenuModifierGroupPatchPayload({
        ...modifierGroupState,
        modifiers: modifierGroupState.modifiers?.map(
          (modifier: any) => modifier.id
        ),
      })
    )
  }

  const handleModifierDetailsUpdate = (updatedModifier: any) => {
    setModifierGroupState((state) => ({
      ...state,
      modifiers: state?.modifiers?.map((modifier) =>
        modifier.id === updatedModifier.id ? updatedModifier : modifier
      ),
    }))
  }

  const handleModifierChange = (_: any, newValues: any) => {
    setModifierGroupState((state) => ({
      ...state,
      modifiers: newValues.length
        ? newValues.map((modifier: any) => ({
            ...modifier,
            price: modifier.price / 100,
          }))
        : [],
    }))
  }

  const handleOpenModifierGroupClick = (index: any, group: any) => {
    if (selectedGroupIndex === index) {
      setSelectedGroupIndex(null)
      setModifierGroupState(defaultMenuModifierGroupState)
      setModifierGroupId(undefined)
    } else {
      setSelectedGroupIndex(index)
      setModifierGroupState(group)
      setModifierGroupId(group.id)
    }
  }

  const menuModifiers = useMenuModifiers(locationId!, menuId!)
  const menuModifiersData = menuModifiers?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const catalogModifierGroup = useModifierGroupById(
    locationId!,
    modifierGroupState.catalogModifierGroupId
  )
  const catalogModifierGroupData = catalogModifierGroup.data?.data

  const modifierOptions = menuModifiersData?.filter((group: any) =>
    catalogModifierGroupData?.modifiers?.some(
      (modifier: any) => modifier.id === group.catalogModifierId
    )
  )
  return (
    <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
      <TableContainer style={{ border: '1px solid #eee', borderRadius: 14 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage({
                  id: 'label_modifier_groups',
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedModifierGroups?.map((group: any, index: any) => (
              <Fragment>
                <TableRow
                  onClick={() => handleOpenModifierGroupClick(index, group)}
                  style={{
                    backgroundColor:
                      selectedGroupIndex === index ? '#eee' : 'transparent',
                  }}
                >
                  <TableCell>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="subtitle1" component="p">
                        {group.name}
                      </Typography>
                      <IconButton>
                        <KeyboardArrowDownIcon
                          style={{
                            fontSize: '24px',
                            transform:
                              selectedGroupIndex === index
                                ? 'rotate(180deg)'
                                : 'none',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedGroupIndex === index && (
                  <StyledTableRow>
                    <TableCell colSpan={3} style={{ padding: 0 }}>
                      <Box
                        sx={{
                          padding: 6,
                          paddingTop: 2,
                          paddingBottom: 2,
                          backgroundColor: '#eee',
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={6} md={9}>
                            <Box marginTop={1}>
                              <Typography variant="h6" component="h1">
                                {intl.formatMessage({
                                  id: 'action_update',
                                })}{' '}
                                {intl.formatMessage({
                                  id: 'label_modifier_group',
                                })}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <Button
                              variant="contained"
                              disabled={!isValid}
                              onClick={handleModifierGroupUpdate}
                              color="success"
                              sx={{ color: 'white', ml: 2 }}
                            >
                              {intl.formatMessage({ id: 'action_update' })}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                          <Grid item xs={12} sm={12}>
                            <CommonTextField
                              id="outlined-basic"
                              label={`${intl.formatMessage({
                                id: 'label_name',
                              })}${' *'}`}
                              onChange={handleNameChange}
                              value={modifierGroupState?.name || ''}
                              autoFocus={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} mt={2}>
                            <CommonTextField
                              id="outlined-multiline-static"
                              label={intl.formatMessage({
                                id: 'label_description',
                              })}
                              onChange={handleDescriptionChange}
                              value={modifierGroupState?.description || ''}
                              rows={2}
                              multiline
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} mt={2}>
                            <Autocomplete
                              multiple
                              limitTags={4}
                              disableCloseOnSelect
                              options={modifierOptions ? modifierOptions : []}
                              value={
                                modifierOptions?.filter((option: any) =>
                                  modifierGroupState.modifiers?.some(
                                    (selectedModifier: any) =>
                                      selectedModifier.id === option.id
                                  )
                                ) || []
                              }
                              onChange={handleModifierChange}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <FormControlLabel
                                    control={<Checkbox checked={selected} />}
                                    label={option.name}
                                  />
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${intl.formatMessage({
                                    id: 'label_modifiers',
                                  })}`}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} mt={2}>
                            <CommonTextField
                              id="outlined-basic"
                              label={intl.formatMessage({
                                id: 'label_min_required',
                              })}
                              onChange={handleMinimumChange}
                              value={modifierGroupState?.minPermitted || 0}
                              error={errorMessage !== ''}
                              helperText={errorMessage ? errorMessage : ' '}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} mt={2}>
                            <CommonTextField
                              id="outlined-basic"
                              label={intl.formatMessage({
                                id: 'label_max_permitted',
                              })}
                              onChange={handleMaximumChange}
                              value={modifierGroupState?.maxPermitted || 0}
                            />
                          </Grid>
                          {modifierGroupState?.modifiers?.length !== 0 && (
                            <ModifierTabs
                              modifiers={modifierGroupState.modifiers}
                              onModifierUpdate={handleModifierDetailsUpdate}
                            />
                          )}
                        </Grid>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
