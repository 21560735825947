import {
  MenuCategoryCreateEntity,
  MenuCategoryPatchEntity,
  MenuCreateEntity,
  MenuItemCreateEntity,
  MenuItemPatchEntity,
  MenuPatchEntity,
  MenuModifierPatchEntity,
  MenuModifierGroupCreateEntity,
  MenuModifierGroupPatchEntity,
  MenuModifierCreateEntity,
} from '../../../../types'

/** MENU */
export const defaultMenuState: MenuCreateEntity | MenuPatchEntity = {
  name: '',
  description: '',
  serviceAvailability: [],
  locationId: '',
  fulfillmentTypes: ['DELIVERY', 'PICKUP', 'DINE-IN'],
  image: '',
}

export const extractMenuPatchPayload = (
  menu: MenuCreateEntity | MenuPatchEntity
): MenuPatchEntity => {
  return {
    name: menu.name,
    description: menu.description,
    serviceAvailability: menu.serviceAvailability,
    fulfillmentTypes: menu.fulfillmentTypes,
  }
}
export const extractMenuCreatePayload = (
  menu: MenuCreateEntity | MenuPatchEntity
): MenuCreateEntity => {
  return {
    name: menu.name!,
    description: menu.description!,
    serviceAvailability: menu.serviceAvailability!,
    fulfillmentTypes: menu.fulfillmentTypes!,
  }
}

export const validateMenuEntity = (
  menu: MenuCreateEntity | MenuPatchEntity
) => {
  return (
    menu &&
    menu.name?.trim() !== '' &&
    menu.fulfillmentTypes?.length &&
    menu.serviceAvailability?.some(
      (res: any) => res.timePeriods && res.timePeriods.length > 0
    )
  )
}

/** Category */

export const defaultCategoryState:
  | MenuCategoryCreateEntity
  | MenuCategoryPatchEntity = {
  name: '',
  description: '',
  showOnline: true,
}

export const extractCategoryCreatePayload = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
): MenuCategoryCreateEntity => {
  return {
    name: category.name!,
    showOnline: category.showOnline!,
    description: category.description!,
    position: category.position!,
    imageUrl: category.imageUrl!,
  }
}

export const extractCategoryPatchPayload = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
): MenuCategoryPatchEntity => {
  return {
    name: category.name!,
    showOnline: category.showOnline!,
    description: category.description!,
    position: category.position!,
    imageUrl: category.imageUrl!,
  }
}

export const validateCategoryEntity = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
) => {
  return category && category.name?.trim() !== ''
}

/** Modifier */

export const defaultMenuModifierState: MenuModifierPatchEntity = {
  name: '',
  description: '',
  price: 0,
}

export const extractMenuModifierPatchPayload = (
  modifier: MenuModifierPatchEntity
): MenuModifierPatchEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: Math.round(parseFloat(modifier?.price!.toString()) * 100),
    position: modifier.position!,
    imageUrl: modifier.imageUrl!,
    showOnline: modifier.showOnline!,
  }
}

export const validateMenuModifierEntity = (
  modifier: MenuModifierCreateEntity | MenuModifierPatchEntity
) => {
  return (
    modifier.name?.trim() !== '' &&
    modifier.price !== undefined &&
    !isNaN(modifier.price) &&
    modifier.showOnline !== undefined
  )
}

/** Modifier Group */

export const defaultMenuModifierGroupState: MenuModifierGroupPatchEntity = {
  name: '',
  description: '',
  maxPermitted: 0,
  minPermitted: 0,
  modifiers: [],
}

export const extractMenuModifierGroupPatchPayload = (
  modifierGroup: MenuModifierGroupPatchEntity
): MenuModifierGroupPatchEntity => {
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    maxPermitted: modifierGroup.maxPermitted!,
    minPermitted: modifierGroup.minPermitted!,
    modifiers: modifierGroup.modifiers!,
    position: modifierGroup.position!,
  }
}

export const validateMenuModifierGroupEntity = (
  modifierGroup: MenuModifierGroupCreateEntity | MenuModifierGroupPatchEntity
) => {
  return (
    modifierGroup &&
    modifierGroup.name?.trim() !== '' &&
    (modifierGroup.minPermitted === undefined ||
      modifierGroup.maxPermitted === undefined ||
      modifierGroup.minPermitted <= modifierGroup.maxPermitted ||
      (modifierGroup.minPermitted === 1 && modifierGroup.maxPermitted === 0))
  )
}

/** ITEMS */

export const validateItemEntity = (
  item: MenuItemCreateEntity | MenuItemPatchEntity
) => {
  return (
    item &&
    item.price?.toLocaleString().length !== 0 &&
    item.price !== undefined &&
    item.name?.trim() !== ''
  )
}
export const defaultItemPatchState: MenuItemPatchEntity = {
  name: '',
  description: '',
  modifierGroups: [],
  categories: [],
  showOnline: false,
  imageUrl: '',
  price: 0,
  position: 0,
}

export const validationCheckUpdateforMenuItem = (updatedState: any) => {
  const validationResults: any = {}
  if (updatedState.imageUrl || updatedState.imageUrl === '') {
    validationResults.imageUrl =
      updatedState.imageUrl?.trim() !== '' && updatedState.imageUrl !== null
  }
  if (updatedState.position || updatedState.position === null) {
    validationResults.position =
      updatedState.position !== null && updatedState.position !== undefined
  }
  if (updatedState.name || updatedState.name === '') {
    validationResults.name = updatedState.name?.trim() !== ''
  }
  if (updatedState.price || updatedState.price === null) {
    validationResults.price = updatedState.price !== null
  }
  if (updatedState.fulfillmentTypes || updatedState.fulfillmentTypes === null) {
    validationResults.fulfillmentTypes =
      updatedState.fulfillmentTypes !== null &&
      updatedState.fulfillmentTypes !== undefined
  }
  if (updatedState.modifierGroups || updatedState.modifierGroups === null) {
    validationResults.modifierGroups =
      updatedState.modifierGroups != null &&
      updatedState.modifierGroups !== undefined
  }
  if (updatedState.categories || updatedState.categories === null) {
    validationResults.categories =
      updatedState.categories !== null && updatedState.categories !== undefined
  }
  if (
    updatedState.serviceAvailability ||
    updatedState.serviceAvailability === null
  ) {
    validationResults.serviceAvailability =
      updatedState?.serviceAvailability !== undefined &&
      updatedState.serviceAvailability !== null
  }
  if (updatedState.showOnline || updatedState.showOnline === null) {
    validationResults.showOnline =
      updatedState.showOnline !== null && updatedState.showOnline !== undefined
  }
  if (updatedState.description) {
    validationResults.description = updatedState.description?.trim() !== ''
  }
  return validationResults
}
