import {
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { getLocationQueryById } from '../../../hooks'
import { AppLoader } from '../../../components'
import { FormattedPhoneNumber } from '../../../components/FormattedPhoneNumber'
import { FormattedTimeZone } from '../../../components/FormattedTimeZone'
const StyledTable = styled(Table)(({ theme }) => ({
  maxWidth: 380,
  border: '1px solid #ccc',
  borderRadius: theme.spacing(2),
  tableLayout: 'fixed',
  marginTop: theme.spacing(1),
  '& .MuiTableRow-root .MuiTableCell-root': {
    cursor: 'default',
    padding: '0px !important',
    textAlign: 'center',
  },
  '& .MuiTableRow-root .MuiTableCell-root:first-child': {
    borderRight: '1px solid #ccc',
  },
  '& .MuiTableCell-root .MuiTable-root': {
    borderWidth: '0px',
  },
  '& .MuiTableCell-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root': {
    borderBottom: '1px solid #ccc',
  },
  '& .MuiTableRow-root:last-child .MuiTableCell-root .MuiTable-root .MuiTableRow-root:last-child .MuiTableCell-root':
    {
      borderBottom: 'none',
    },
}))
export const LocationOverview = () => {
  const { locationId } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const location = getLocationQueryById(locationId!)
  const locationData = location?.data?.data
  if (location.isLoading) {
    return <AppLoader />
  }
  const formatTime = (time: any) => {
    const [hours, minutes] = time.split(':')
    let formattedTime = ''
    if (hours >= 12) {
      formattedTime += (hours % 12 || 12) + ':' + minutes + ' PM'
    } else {
      formattedTime += hours + ':' + minutes + ' AM'
    }
    return formattedTime
  }

  return (
    <>
      <Paper sx={{ borderRadius: 2 }}>
        <Box sx={{ paddingX: 3, paddingY: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>
                <Typography variant="h6" component="h1">
                  {intl.formatMessage({ id: 'label_location_details' })}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Box marginLeft={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/app/locations/${locationId}`)}
                  >
                    {intl.formatMessage({ id: 'action_update' })}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <List>
              <ListItemText sx={{ marginTop: 1 }}>
                <Typography component="span" color="action">
                  <Typography component={'span'} color={'grayText'}>
                    {intl.formatMessage({ id: 'label_name' })}
                    {' :'}
                  </Typography>
                  <Typography component={'span'} sx={{ marginLeft: 1 }}>
                    {locationData?.name}
                  </Typography>
                </Typography>
              </ListItemText>
              <ListItemText sx={{ marginTop: 1 }}>
                <Typography component="span" color="action">
                  <Typography component={'span'} color={'grayText'}>
                    {intl.formatMessage({ id: 'label_address' })}
                    {' :'}
                  </Typography>
                  <Typography component={'span'} sx={{ marginLeft: 1 }}>
                    {locationData?.address?.address}
                  </Typography>
                </Typography>
              </ListItemText>
              {locationData?.phoneNumber && (
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_phone_number' })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      <FormattedPhoneNumber
                        phoneNumber={locationData?.phoneNumber}
                      />
                    </Typography>
                  </Typography>
                </ListItemText>
              )}
              {locationData?.timezone && (
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_timezone' })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      <FormattedTimeZone value={locationData?.timezone} />
                    </Typography>
                  </Typography>
                </ListItemText>
              )}
              <ListItemText sx={{ marginTop: 1 }}>
                <Typography component={'span'} color={'grayText'}>
                  {intl.formatMessage({ id: 'label_business_hours' })}
                  {' :'}
                </Typography>
                <StyledTable size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {intl.formatMessage({ id: 'label_location_details' })}
                      </TableCell>
                      <TableCell>
                        <Table style={{ tableLayout: 'fixed' }}>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {intl.formatMessage({ id: 'label_start_time' })}
                              </TableCell>
                              <TableCell>
                                {intl.formatMessage({ id: 'label_end_time' })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationData?.businessHours &&
                      locationData.businessHours.map((item: any) => {
                        return (
                          item.timePeriods.length > 0 && (
                            <TableRow sx={{ cursor: 'default' }}>
                              <TableCell>{item.weekday}</TableCell>
                              <TableCell sx={{ borderWidth: '0px !important' }}>
                                <Table style={{ tableLayout: 'fixed' }}>
                                  <TableBody>
                                    {item.timePeriods.map((time: any) => {
                                      return (
                                        <TableRow>
                                          <TableCell>
                                            {formatTime(time.startTime)}
                                          </TableCell>
                                          <TableCell>
                                            {formatTime(time.endTime)}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      })}
                  </TableBody>
                </StyledTable>
              </ListItemText>
            </List>
          </Box>
        </Box>
      </Paper>
    </>
  )
}
