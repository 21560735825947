import { FC } from 'react'

type LogoValue =
  | 'UBEREATS'
  | 'DELIVEROO'
  | 'DOORDASH'
  | 'FOODHUB'
  | 'GRUBHUB'
  | 'JUSTEAT'

interface LogoProps {
  value: string
}

const logoUrls: Record<LogoValue, string> = {
  UBEREATS:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/ubereats.webp',
  DELIVEROO:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/deliveroo.webp',
  DOORDASH:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/doordash.webp',
  FOODHUB:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/foodhub.webp',
  GRUBHUB:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/grubhub.webp',
  JUSTEAT:
    'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/justeat.webp',
}

const defaultLogoUrl =
  'https://falcon-sit-tpi-dashboard-assetsb7c36b1b-o2c64zhmfdzm.s3.amazonaws.com/tpi_logos/no_image.jpeg'

const Logo: FC<LogoProps> = ({ value }) => {
  if (value in logoUrls) {
    const logoUrl = logoUrls[value as LogoValue]
    return (
      <img
        style={{ width: 40, height: 35, borderRadius: '6px' }}
        src={logoUrl}
        alt={`${value} Logo`}
      />
    )
  }

  return (
    <img
      style={{ width: 40, height: 35, borderRadius: '6px' }}
      src={defaultLogoUrl}
      alt="Default Logo"
    />
  )
}

export default Logo
