import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useProductById,
  useCatalogCategory,
  useModifierGroups,
  getLocationQueryById,
} from '../../../../../../hooks'
import { defaultProductData } from '../../../../../util'
import {
  StyledTablePaperWrapper,
  PageNavigationView,
  AppLoader,
} from '../../../../../../components'
import {
  CatalogProductCreateEntity,
  CatalogProductPatchEntity,
} from '../../../../../../types'
import { getCurrencySymbol } from '../../../../../util'

export function ProductView() {
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const { locationId, productId } = useParams()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      label: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      label: 'Items',
      path: `/app/locations/${locationId}/catalog/items`,
    },
  ]
  const [price, setPriceData] = useState<any>()
  const product = useProductById(locationId, productId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setProductState(data.data)
      setPriceData(data.data?.price! / 100)
    },
  })

  const categories = useCatalogCategory(locationId!)
  const modifiers = useModifierGroups(locationId!)
  const categoriesData = categories.data?.pages
    .map((value) => value.data)
    .flat()
  const modifiersData = modifiers.data?.pages.map((value) => value.data).flat()
  useEffect(() => {
    categories.hasNextPage && categories.fetchNextPage()
  }, [categories.data])
  useEffect(() => {
    modifiers.hasNextPage && modifiers.fetchNextPage()
  }, [modifiers.data])
  const categoryOptions =
    (!categories.hasNextPage && categoriesData?.map((record) => record)) || []

  const modifierOptions =
    (!modifiers.hasNextPage && modifiersData?.map((record) => record)) || []

  const [productState, setProductState] = useState<
    CatalogProductPatchEntity | CatalogProductCreateEntity
  >(defaultProductData)

  const selectedCategoryNames = (productState.categories || [])
    .map((categoryId) => {
      const selectedCategory = categoryOptions?.find(
        (option) => option.id === categoryId
      )
      return selectedCategory ? selectedCategory.name : ''
    })
    .join(', ')
  const selectedModifierGroupNames = (productState.modifierGroups || [])
    .map((categoryId) => {
      const selectedCategory = modifierOptions?.find(
        (option) => option.id === categoryId
      )
      return selectedCategory ? selectedCategory.name : ''
    })
    .join(', ')

  if (product.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }
  if (
    product.isLoading ||
    !productState?.id ||
    modifiers.hasNextPage ||
    categories.hasNextPage ||
    modifiers.isLoading ||
    categories.isLoading
  ) {
    return <AppLoader />
  }

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="primary"
                onClick={() =>
                  navigate(`/app/locations/${locationId}/catalog/items`)
                }
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} md={9} sm={12}>
              <Box>
                <List>
                  <ListItemText sx={{ marginTop: 1 }}>
                    <Typography component="span" color="action">
                      <Typography component={'span'} color={'grayText'}>
                        {'InStock :'}
                      </Typography>
                      <Typography component={'span'} sx={{ marginLeft: 1 }}>
                        {productState.showOnline ? 'true' : 'false'}
                      </Typography>
                    </Typography>
                  </ListItemText>
                  {productState.name && (
                    <ListItemText sx={{ marginTop: 1 }}>
                      <Typography component="span" color="action">
                        <Typography component={'span'} color={'grayText'}>
                          {intl.formatMessage({
                            id: 'label_name',
                          })}
                          {' :'}
                        </Typography>
                        <Typography component={'span'} sx={{ marginLeft: 1 }}>
                          {productState.name}
                        </Typography>
                      </Typography>
                    </ListItemText>
                  )}
                  <ListItemText sx={{ marginTop: 1 }}>
                    <Typography component="span" color="action">
                      <Typography component={'span'} color={'grayText'}>
                        {intl.formatMessage({
                          id: 'label_price',
                        })}
                        {' :'}
                      </Typography>
                      <Typography component={'span'} sx={{ marginLeft: 1 }}>
                        {currency} {price}
                      </Typography>
                    </Typography>
                  </ListItemText>
                  {selectedCategoryNames && (
                    <ListItemText sx={{ marginTop: 1 }}>
                      <Typography component="span" color="action">
                        <Typography component={'span'} color={'grayText'}>
                          {intl.formatMessage({ id: 'label_category' })}
                          {' :'}
                        </Typography>
                        <Typography component={'span'} sx={{ marginLeft: 1 }}>
                          {selectedCategoryNames}
                        </Typography>
                      </Typography>
                    </ListItemText>
                  )}
                  {selectedModifierGroupNames && (
                    <ListItemText sx={{ marginTop: 1 }}>
                      <Typography component="span" color="action">
                        <Typography component={'span'} color={'grayText'}>
                          {intl.formatMessage({
                            id: 'label_modifier_group',
                          })}
                          {' :'}
                        </Typography>
                        <Typography component={'span'} sx={{ marginLeft: 1 }}>
                          {selectedModifierGroupNames}
                        </Typography>
                      </Typography>
                    </ListItemText>
                  )}
                  {productState.description && (
                    <ListItemText sx={{ marginTop: 1 }}>
                      <Typography component="span" color="action">
                        <Typography component={'span'} color={'grayText'}>
                          {intl.formatMessage({ id: 'label_description' })}
                          {' :'}
                        </Typography>
                        <Typography component={'span'} sx={{ marginLeft: 1 }}>
                          {productState.description}
                        </Typography>
                      </Typography>
                    </ListItemText>
                  )}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Box sx={{ display: 'flex' }}>
                <img
                  src={product.data?.data?.imageUrl}
                  width="100%"
                  style={{ borderRadius: '8px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
