import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  TextField,
} from '@mui/material'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useMemo, useState } from 'react'
import {
  MenuModifierGroupCreateEntity,
  MenuModifierGroupPatchEntity,
} from '../../../../../../../types'
import {
  useMenuModifiers,
  useMenuModifierGroupUpdate,
  useMenuModifierGroupById,
} from '../../../../../../../hooks'
import {
  AppLoader,
  CommonTextField,
  StyledTablePaperWrapper,
} from '../../../../../../../components'
import { useIntl } from 'react-intl'

import {
  defaultMenuModifierGroupState,
  extractMenuModifierGroupPatchPayload,
  validateMenuModifierGroupEntity,
} from '../../../utils'

export function MenuModifierGroupUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, menuId, modifierGroupId } = useParams()

  const [modifierGroupState, setModifierGroupState] = useState<
    MenuModifierGroupCreateEntity | MenuModifierGroupPatchEntity
  >(defaultMenuModifierGroupState)
  const isValid = useMemo(
    () => validateMenuModifierGroupEntity(modifierGroupState),
    [modifierGroupState]
  )

  const modifers = useMenuModifiers(locationId!, menuId!)
  const modifierOptions = modifers.data?.pages
    .map((value) => value.data)
    .flat()
    ?.map((record) => record)
  const sortedModifierOptions = modifierOptions?.sort(
    (a: any, b: any) => a.position - b.position
  )

  const selectedModifierNames = (modifierGroupState.modifiers || [])
    .map((modifierId) => {
      const selectedCategory = modifierOptions?.find(
        (option) => option.id === modifierId
      )
      return selectedCategory ? selectedCategory.name : ''
    })
    .join(', ')
  // const locationsList = useLocations()
  //   .data?.pages?.map((value) => value.data)
  //   .flat()
  // const locationName = findNameById(locationId, locationsList)
  // let navigationPages: any = [
  //   { label: 'Locations', path: '/app/locations' },
  //   { label: locationName, path: `/app/locations/${locationId}/analytics` },
  //   {
  //     label: 'Menus',
  //     path: `/app/locations/${locationId}/menus`,
  //   },
  //   // {
  //   //   label: 'Modifier-group',
  //   //   path: `/app/locations/${locationId}/catalog/modifier-group`,
  //   // },
  // ]

  const modifierGroupUpdate = useMenuModifierGroupUpdate(
    locationId!,
    menuId!,
    modifierGroupId!
  )
  const modifierGroupById = useMenuModifierGroupById(
    locationId,
    menuId,
    modifierGroupId,
    {
      refetchOnMount: 'always',
      onSuccess: (data) => {
        data?.data && setModifierGroupState(data.data)
      },
    }
  )

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        minPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
    }
  }
  const handleMaximumChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        maxPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
    }
  }

  const handleModifierChange = (_: any, newValues: any) => {
    setModifierGroupState((state) => ({
      ...state,
      modifiers: newValues.map((newValue: any) => newValue.id),
    }))
  }

  const handleProductChange = async () => {
    modifierGroupUpdate.mutate(
      extractMenuModifierGroupPatchPayload(modifierGroupState)
    )
  }

  if (
    modifierGroupUpdate.isLoading ||
    modifierGroupById.isFetching ||
    modifierGroupById.isLoading
  ) {
    return <AppLoader />
  }

  if (modifierGroupUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/menu/${menuId}/modifierGroups`}
        replace
      />
    )
  }

  return (
    <>
      {/* <PageNavigationView navigationPages={navigationPages} /> */}
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={9}>
              <Box marginTop={1}>
                <Typography variant="h6" component="h1">
                  {modifierGroupId
                    ? `${intl.formatMessage({ id: 'action_update' })}`
                    : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                  {intl.formatMessage({ id: 'label_modifier_group' })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="inherit"
                onClick={() =>
                  navigate(
                    `/app/locations/${locationId}/menu/${menuId}/modifierGroups`
                  )
                }
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
              <Button
                variant="contained"
                disabled={!isValid}
                onClick={handleProductChange}
                color="success"
                sx={{ color: 'white', ml: 2 }}
              >
                {modifierGroupId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                id="outlined-basic"
                label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
                onChange={handleNameChange}
                value={modifierGroupState.name || ''}
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                limitTags={2}
                disableCloseOnSelect
                options={sortedModifierOptions ? sortedModifierOptions : []}
                value={
                  sortedModifierOptions?.filter((option: any) =>
                    modifierGroupState.modifiers?.includes(option.id)
                  ) || []
                }
                onChange={handleModifierChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      control={<Checkbox checked={selected} />}
                      label={option.name}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${intl.formatMessage({
                      id: 'label_modifiers',
                    })}`}
                    variant="outlined"
                    value={selectedModifierNames}
                  />
                )}
              />
              {/* <Button onClick={() => setOpen(true)}>
                {intl.formatMessage({ id: 'action_add_items' })}
              </Button> */}
            </Grid>
            <Grid item mt={{ xs: 2, sm: 1 }} xs={12}>
              <CommonTextField
                id="outlined-multiline-static"
                label={intl.formatMessage({ id: 'label_description' })}
                onChange={handleDescriptionChange}
                value={modifierGroupState.description || ''}
                rows={4}
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={5.9} mt={2} mr={{ md: 2, sm: 1.2, xs: 0 }}>
              <CommonTextField
                id="outlined-basic"
                label={intl.formatMessage({ id: 'label_min_required' })}
                onChange={handleMinimumChange}
                value={modifierGroupState.minPermitted || 0}
              />
            </Grid>
            <Grid item xs={12} sm={5.9} mt={2}>
              <CommonTextField
                id="outlined-basic"
                label={intl.formatMessage({ id: 'label_max_permitted' })}
                onChange={handleMaximumChange}
                value={modifierGroupState.maxPermitted || 0}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
