import {
  Box,
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AppLoader,
  ClickableIcon,
  NoRecordFound,
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
} from '../../../../../components'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useProducts } from '../../../../../hooks'
import {
  defaultImageUrl,
  shortenText,
  getCurrencySymbol,
  tableImageStyles,
} from '../../../../util'
import { FormattedDateTime } from '../../../../../components/FormattedDateTime'
import { useEffect } from 'react'

export function Products() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId } = useParams()
  const currency = getCurrencySymbol()
  const products = useProducts(locationId!)
  const records = products.data?.pages?.map((value) => value.data).flat()
  const sortedRecords = records?.sort(
    (a: any, b: any) => a.position - b.position
  )
  const tableImageStyles = { width: 50, height: 50, borderRadius: 8 }
  useEffect(() => {
    products.hasNextPage && products.fetchNextPage()
  }, [products.data])
  if (products.isLoading) {
    return <AppLoader />
  }
  if (products.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  return (
    <>
      {products.isSuccess && !sortedRecords?.length ? (
        <NoRecordFound title="products" />
      ) : (
        <>
          {products.hasNextPage && (
            <Typography component="p" variant="body2" color="textSecondary">
              {intl.formatMessage({ id: 'label_loading_items' })}
            </Typography>
          )}
          <StyledTablePaperWrapper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {intl.formatMessage({ id: 'label_image' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'label_name' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'label_price' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'label_show_online' })}
                    </TableCell>
                    <TableCell sx={{ width: '300px' }}>
                      {intl.formatMessage({ id: 'label_created_at' })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({ id: 'label_actions' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRecords?.map((productData, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        onClick={() =>
                          navigate(
                            `/app/locations/${locationId}/catalog/product/${productData.id}/view`
                          )
                        }
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                            }}
                          >
                            <img
                              style={{ ...tableImageStyles }}
                              src={
                                productData.imageUrl
                                  ? productData.imageUrl
                                  : defaultImageUrl
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          {productData.name?.length > 25 ? (
                            <Tooltip title={productData.name}>
                              <Typography variant="body2">
                                {shortenText(productData.name, 25)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            productData.name
                          )}
                        </TableCell>
                        <TableCell>
                          {currency}{' '}
                          {productData.price
                            ? (
                                parseFloat(productData.price.toString()) / 100
                              ).toFixed(2)
                            : '0.00'}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={productData.showOnline}
                            disabled
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <FormattedDateTime value={productData.createdAt} />
                        </TableCell>
                        <TableCell onClick={(e) => e.stopPropagation()}>
                          <Box sx={{ display: 'flex' }}>
                            <ClickableIcon
                              handleNavigate={() =>
                                navigate(
                                  `/app/locations/${locationId}/catalog/product/${productData.id}/view`
                                )
                              }
                              children={
                                <VisibilityOutlinedIcon
                                  onClick={() =>
                                    navigate(
                                      `/app/locations/${locationId}/catalog/product/${productData.id}/view`
                                    )
                                  }
                                />
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {products.hasNextPage && (
              <StyledButtonContainerForTable
                display="flex"
                justifyContent="center"
                py={2}
              >
                <Button
                  onClick={() => products.fetchNextPage()}
                  disabled={products.isFetching}
                  variant="outlined"
                  color="primary"
                >
                  {products.isFetching
                    ? `${intl.formatMessage({ id: 'status_loading' })}`
                    : `${intl.formatMessage({ id: 'action_load_more' })}`}
                </Button>
              </StyledButtonContainerForTable>
            )}
          </StyledTablePaperWrapper>
        </>
      )}
    </>
  )
}
