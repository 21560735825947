import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import {
  Alert,
  Box,
  Button,
  CardActions,
  IconButton,
  Stack,
} from '@mui/material'
import { Delete, DoneOutlined, LinkOutlined } from '@mui/icons-material'
import { FC, useState } from 'react'
import { useAuthStore } from '../../hooks'
import { createAllowedAuthorizeUrl } from '../../pages/Authorize'
import { useIntl } from 'react-intl'
import { StyledIconAsButton } from '../MUIStyledComponents'
import { ApplicationEntity } from '../../types'
import { CardAvatar } from './CardAvatar'
import { BaseCard } from './BaseCard'
import { SyncCatalogDialog } from '../SyncCatalogDialog'

export interface ApplicationCardProps {
  app: ApplicationEntity & {
    isEnabled: boolean
    connected: boolean
    isCatalogSyncEnabled: boolean
  }
  locationId: string
  setConnectApp?: any
  isDelete?: boolean
  handleDeleteApp?: any
  disabled?: boolean
}

const createAuthorizeUrl = (
  token: string,
  props: ApplicationCardProps,
  other?: Record<string, any>
) => {
  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: props.app.id,
    redirect_uri: props.app.redirectUris[0],
    response_type: 'code',
    scope: 'provisioning',
    location_id: props.locationId,
    token,
    ...other,
  })

  return params
}

export const ApplicationCard: FC<ApplicationCardProps> = (props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const {
    app,
    handleDeleteApp,
    isDelete,
    locationId,
    setConnectApp,
    disabled,
  } = props
  const [syncCatalogDialogOpen, setSyncCatalogDialogOpen] = useState(false)
  const token = useAuthStore((state) => state.token)
  const [accountId] = useAuthStore((t) => [t.accountId])
  const handleEnable = () => {
    if (locationId != null) {
      setConnectApp(false)
      window.open(
        `${API_ENDPOINT}/oauth2/authorize?${createAuthorizeUrl(
          token?.access_token!,
          { ...props },
          { account_id: accountId }
        )}`,
        '_blank'
      )
    } else {
      setOpen(true)
    }
  }

  const handleDisable = () => {
    window.open(
      createAllowedAuthorizeUrl(
        createAuthorizeUrl(token?.access_token!, props),
        token?.access_token!,
        accountId!,
        locationId
      ),
      '_blank'
    )
  }
  const handleCardOnclick = () => {
    if (!disabled) {
      if (app.isEnabled) {
        handleDisable()
      } else {
        handleEnable()
      }
    } else {
      setConnectApp(true)
    }
  }
  return (
    <>
      <BaseCard
        onClick={handleCardOnclick}
        disabled={disabled}
        sx={{ width: '100%' }}
      >
        <CardContent style={{ display: 'flex', padding: 10 }}>
          <Box sx={{ p: 1 }}>
            <CardAvatar src={app.image} />
          </Box>
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" component="div">
              {app.name}
            </Typography>
            <Typography variant="body2">{app.description}</Typography>
          </Box>
        </CardContent>
        {open && (
          <Box>
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="warning">
                {intl.formatMessage({
                  id: 'action_create_location_to_connect_app',
                })}
              </Alert>
            </Stack>
          </Box>
        )}
        <CardActions disableSpacing sx={{ mt: 'auto' }}>
          {isDelete && (
            <IconButton
              onClick={(e) => {
                handleDeleteApp(app)
                e.stopPropagation()
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          )}
          {app.isEnabled &&
            app.category === 'POS' &&
            app.isCatalogSyncEnabled === true && (
              <Button
                size="small"
                color="primary"
                onClick={(e) => {
                  setSyncCatalogDialogOpen(true)
                  e.stopPropagation()
                }}
              >
                Sync Catalog
              </Button>
            )}
        </CardActions>
      </BaseCard>
      {syncCatalogDialogOpen && (
        <SyncCatalogDialog
          open={true}
          applicationId={app.id}
          locationId={locationId}
          onClose={() => setSyncCatalogDialogOpen(false)}
        />
      )}
    </>
  )
}
const absoluteStyles = {
  position: 'absolute',
  top: '8px',
  left: '8px',
}
export const ConnectButton = () => {
  return (
    <StyledIconAsButton
      size="small"
      color="primary"
      variant="contained"
      sx={{ ...absoluteStyles }}
    >
      <LinkOutlined />
    </StyledIconAsButton>
  )
}

export const Disconnectbutton = () => {
  return (
    <StyledIconAsButton
      size="small"
      color="success"
      variant="contained"
      sx={{ ...absoluteStyles }}
    >
      <DoneOutlined />
    </StyledIconAsButton>
  )
}
