import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useAccessToken } from './useAccessToken'
import { useAuthStore } from './useAuthStore'
import { signalToCancellationToken } from './utils'
import {
  CatalogModifierCreateEntity,
  CatalogModifierPatchEntity,
  ErrorResponse,
  GetCatalogModifierByIdResponse,
  ListCatalogModifierResponse,
} from '../types'
import { api } from '../services/api'
import { toast } from 'react-toastify'

export interface UseModifierByIdProps<
  TData = GetCatalogModifierByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useModifiers = (locationId: string) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListCatalogModifierResponse>(
    ['accounts', accountId, 'locations', locationId, 'modifiers'],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifiers`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}

export const useModifierCreate = (locationId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogModifierByIdResponse,
    ErrorResponse,
    CatalogModifierCreateEntity
  >(
    (modifier) =>
      api
        .post<GetCatalogModifierByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifiers`,
          modifier,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'modifiers',
          ],
          exact: true,
        })
        toast.success('Modifier Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useModifierUpdate = (locationId: string, modifierId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useMutation<
    GetCatalogModifierByIdResponse,
    ErrorResponse,
    CatalogModifierPatchEntity
  >(
    (modifier) =>
      api
        .patch<GetCatalogModifierByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifiers/${modifierId}`,
          modifier,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'modifiers',
          ],
          exact: true,
        })
        toast.success('Modifier  Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useModifierById = (
  locationId?: string,
  modifierId?: string,
  props?: UseModifierByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetCatalogModifierByIdResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId, 'modifiers', modifierId],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifiers/${modifierId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled:
        !!token?.access_token && !!accountId && !!locationId && !!modifierId,
      ...props,
    }
  )
}

export const useModifierDelete = (locationId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (modifierId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/modifiers/${modifierId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'modifiers',
        ])
        toast.success('Modifier  Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
