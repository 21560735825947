import { InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  label: any
  currency: string
  value: number
  onChange: (data: any) => void
}
export const CommonPriceInputField: React.FC<Props> = ({
  label,
  currency,
  value,
  onChange,
}) => {
  const [price, setPrice] = useState(value)
  useEffect(() => {
    setPrice(value)
  }, [value])

  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
      setPrice(inputValue)
      onChange(inputValue)
    }
  }

  return (
    <TextField
      id="outlined-basic"
      label={label}
      onChange={handlePriceChange}
      type="number" // Ensure the input is numeric and it will allow the user to enter a decimal point
      value={price}
      inputProps={{ step: '0.01', maxLength: 10 }}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{currency}</InputAdornment>
        ),
      }}
    />
  )
}
