import * as moment from 'moment-timezone'
import { useAccounts } from '../hooks'
import {
  CatalogCategoryCreateEntity,
  CatalogCategoryPatchEntity,
  CatalogModifierCreateEntity,
  CatalogModifierGroupCreateEntity,
  CatalogModifierGroupPatchEntity,
  CatalogModifierPatchEntity,
  CatalogProductCreateEntity,
  CatalogProductPatchEntity,
  UserPatchEntity,
} from '../types'
import { HandleUndoOptions, AfterSnackBarClosedParams } from './types'
// @ts-ignore
import noImageSmall from '../assets/no_image_small.jpeg'
// import { useState } from 'react'

export function shortenText(text: any, maxLength: any) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
}

export function formatUtcDate(utcDate: any) {
  return utcDate != null
    ? moment.utc(utcDate).format('MMM DD YYYY, LT')
    : 'invalid date'
}

export function formatLocalDate(date: any) {
  return date != null
    ? moment.utc(date).local().format('MMM DD YYYY, LT')
    : 'invalid date'
}
// export const [showConfirmationDialog, setShowConfirmationDialog] =
//   useState(false)

export function shortenPrice(price: any, maxLength: any) {
  const priceString = price.toString()
  const truncatedPrice =
    priceString.length > maxLength
      ? `${priceString.substring(0, maxLength)}...`
      : priceString
  return parseFloat(truncatedPrice).toFixed(2) // Ensure the price always displays with 2 decimal places
}

export const setUrlParameter = (name: string, value: any) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(name, value)
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`
  window.history.replaceState({ path: newUrl }, '', newUrl)
}

export const getUrlParameter = (name: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(name)
}
export const findIdByName = (value: any, arrayList: any) => {
  const foundItem = arrayList?.find((item: any) => item.name === value)
  return foundItem ? foundItem.id : null
}
export const findNameById = (value: any, arrayList: any) => {
  const foundItem = arrayList?.find((item: any) => item.id === value)
  return foundItem ? foundItem.name : null
}

export const defaultImageUrl = noImageSmall
type CurrencySymbolMap = {
  [key: string]: string
}

export const tableImageStyles = { width: 50, height: 50, borderRadius: 8 }

const currencySymbols: CurrencySymbolMap = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'Fr',
  CNY: '¥',
  INR: '₹',
}
export const getCurrencySymbol = () => {
  const account = useAccounts()
  const currencySymbol = currencySymbols[account.data.data[0].currency]
  return currencySymbol
}
export const getCurrency = () => {
  const account = useAccounts()
  // const accountCurrency = () => {
  //   const account = accounts.data.data.find(
  //     (item: any) => item.id === orderData?.data?.data?.accountId
  //   )

  //   return account ? account.currency : 'GBP'
  // }
  return account.data.data[0].currency
}

export const parseQueryString = (queryString: any) => {
  var params = []
  if (queryString.length > 0) {
    return (params = queryString.split(','))
  }
  return []
}

/** CATEGORY */

export const defaultCatalogCategoryState:
  | CatalogCategoryCreateEntity
  | CatalogCategoryPatchEntity = {
  name: '',
  description: '',
  position: 0,
  posReference: '',
}

export const validateCatalogCategoryEntity = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
) => {
  return category && category.name?.trim() !== ''
}

export const extractCatalogCategoryCreatePayload = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
): CatalogCategoryCreateEntity => {
  return {
    name: category.name!,
    description: category.description!,
    posReference: '',
  }
}

export const extractCatalogCategoryPatchPayload = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
): CatalogCategoryPatchEntity => {
  return {
    name: category.name!,
    description: category.description!,
  }
}

/** PRODUCT */

export const defaultProductData:
  | CatalogProductCreateEntity
  | CatalogProductPatchEntity = {
  name: '',
  description: '',
  price: 0,
  categories: [],
}

export const extractProductPatchPayload = (
  product: CatalogProductPatchEntity | CatalogProductCreateEntity
): CatalogProductPatchEntity => {
  return {
    name: product.name,
    description: product.description,
    price: product.price,
    categories: product.categories,
  }
}
export const validateProductEntity = (
  product: CatalogProductCreateEntity | CatalogProductPatchEntity,
  isLoading: boolean,
  isEdited: boolean
) => {
  return (
    product &&
    product.name!.trim() !== '' &&
    product.price?.toLocaleString().length !== 0 &&
    product.categories?.length !== 0 &&
    !isLoading &&
    isEdited
  )
}

export const extractProductCreatePayload = (
  product: CatalogProductPatchEntity | CatalogProductCreateEntity
): CatalogProductCreateEntity => {
  return {
    name: product.name!,
    description: product.description!,
    price: product.price! * 100,
    showOnline: product.showOnline ?? true,
    modifierGroups: product.modifierGroups ?? [],
    posReference: '',
    categories: product.categories ?? [],
  }
}

/** Modifier */

export const defaultModifierState:
  | CatalogModifierCreateEntity
  | CatalogModifierPatchEntity = {
  name: '',
  description: '',
  price: 0,
  posReference: '',
}

export const validateModifierEntity = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
) => {
  return (
    modifier &&
    modifier.name?.trim() !== '' &&
    modifier.price?.toLocaleString().length !== 0
  )
}

export const extractModifierCreatePayload = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
): CatalogModifierCreateEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: modifier.price! * 100,
    posReference: '',
  }
}

export const extractModifierPatchPayload = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
): CatalogModifierPatchEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: modifier.price! * 100,
  }
}

/** Modifiers Group */

export const defaultModifierGroupState:
  | CatalogModifierGroupCreateEntity
  | CatalogModifierGroupPatchEntity = {
  name: '',
  description: '',
  modifiers: [],
  posReference: '',
  minPermitted: 0,
  maxPermitted: 0,
}

export const validateModifierGroupEntity = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
) => {
  return (
    modifierGroup &&
    modifierGroup.name?.trim() !== '' &&
    modifierGroup.modifiers?.length !== 0
  )
}

export const extractModifierGroupCreatePayload = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
): CatalogModifierGroupCreateEntity => {
  const items = modifierGroup.modifiers!.map((item) => ({
    id: item.id!, // Use a default empty string if id is undefined
    price: item.price!, // Use a default value if price is undefined
    name: item.name!,
    // imageUrl: item.imageUrl!,
  }))
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    modifiers: items!,
    minPermitted: modifierGroup.minPermitted,
    maxPermitted: modifierGroup.maxPermitted,
    posReference: '',
  }
}

export const extractModifierGroupPatchPayload = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
): CatalogModifierGroupPatchEntity => {
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    modifiers: modifierGroup.modifiers!,
    minPermitted: modifierGroup.minPermitted,
    maxPermitted: modifierGroup.maxPermitted,
  }
}

export const isValidString = (data: any) => {
  if (data && (data !== '' || data !== undefined || data !== null)) {
    return true
  } else return false
}

export async function fetchTimeZoneData(latLng: { lat: any; lng: any }) {
  const GOOGLE_MAPS_API_KEY = 'AIzaSyAt_oUjkETF5LF5HkftwDEl7l-hvVROci4'
  const timestamp = Math.floor(Date.now() / 1000) // Current timestamp in seconds
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?language=es&location=${latLng.lat},${latLng.lng}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY}`
    )
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching timezone data:', error)
    return { error: true }
  }
}

export const handleDeleteConfirmed = async (options: any) => {
  const {
    setCategoriesState,
    categoriesState,
    setShowUndoSnackbar,
    deletedList,
    setDeletedList,
    setDeletedIndex,
    setDeletedItem,
    selectedProduct,
    itemsState,
    setItemsState,
    type,
  } = options
  setShowUndoSnackbar(true)
  setDeletedList([...deletedList, selectedProduct.id])
  let deletedItem = null,
    deletedItemIndex = null

  if (type === 'category') {
    const updatedMenu = categoriesState?.filter(
      (cat: any) => ![...deletedList, selectedProduct.id].includes(cat.id)
    )
    deletedItem = categoriesState?.find(
      (cat: any) => cat.id === selectedProduct.id
    )

    deletedItemIndex = categoriesState?.findIndex(
      (cat: any) => cat.id === selectedProduct.id
    )
    setCategoriesState(updatedMenu as any)
  } else {
    const updatedMenu = itemsState?.filter(
      (cat: any) => ![...deletedList, selectedProduct.id].includes(cat.id)
    )
    deletedItem = itemsState?.find((cat: any) => cat.id === selectedProduct.id)

    deletedItemIndex = itemsState?.findIndex(
      (cat: any) => cat.id === selectedProduct.id
    )
    setItemsState(updatedMenu)
  }
  setDeletedItem(deletedItem)
  setDeletedIndex(deletedItemIndex)
}

export const handleSnackbarClose = (reason: any, setShowUndoSnackbar: any) => {
  if (reason === 'clickaway' || reason === 'external') {
    return
  }
  setShowUndoSnackbar(false)
}

const setProductOnUndo = (options: any) => {
  const {
    deletedProduct,
    categoriesState,
    deletedIndex,
    setCategoriesState,
    setItemsState,
    itemsState,
    type,
  } = options

  if (type === 'category') {
    // Create a copy of the menuItems array
    const updatedMenu = categoriesState as any

    if (deletedIndex !== -1) {
      updatedMenu.splice(deletedIndex, 0, deletedProduct)
      const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
        return updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
      })
      setCategoriesState(uniqueMenu)
    }
  } else {
    const updatedMenu = itemsState as any
    if (deletedIndex !== -1) {
      updatedMenu.splice(deletedIndex, 0, deletedProduct)
      const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
        return updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
      })
      setItemsState(uniqueMenu!)
    }
  }
}

export const handleUndo = (options: HandleUndoOptions) => {
  const {
    setShowUndoSnackbar,
    setDeletedList,
    deletedList,
    setHighlightedRow,
  } = options
  setShowUndoSnackbar(false)
  setHighlightedRow(true)
  setDeletedList([...deletedList.slice(0, -1)])
  setProductOnUndo(options)
}
export const afterSnackBarClosed = (options: AfterSnackBarClosedParams) => {
  const {
    setShowUndoSnackbar,
    deletedProduct,
    productDelete,
    setItemError,
    type,
    setCatError,
  } = options

  setShowUndoSnackbar(false)
  //actuall deletion
  productDelete.mutate(deletedProduct.id, {
    onSuccess: () => {
      // setShowConfirmationDialog(false)
    },
    onError: () => {
      type === 'item' ? setItemError() : setCatError()
    },
  })
}

export const highlightedGridStyle = (
  product: any,
  highlightedRow: any,
  deletedProduct: any
) => {
  return {
    cursor: 'default !important',
    backgroundColor:
      highlightedRow && deletedProduct?.id === product.id
        ? '#E2EFFF'
        : 'inherit',
    transition: 'background-color 0.5s linear',
  }
}

export const extractUserPatchPayload = (user: UserPatchEntity) => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language,
    password: user.password!,
  }
}
interface ResellerUserPatchEntity {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  language: string
  accounts: string[]
}
export const extractResellerUserPatchPayload = (
  user: ResellerUserPatchEntity
) => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language,
    password: user.password!,
    phone: user.phone!,
    email: user.email!,
    accounts: user.accounts!,
  }
}
interface ResellerAccountPatchEntity {
  name: string
  currency: string
  phoneNumber: string
  address: {
    country: string
    place_id: string
    lat: number
    lng: number
    formatted_address: string
  }
  ownerId: string
  lastOwnerId?: string
}
export const extractResellerAccountPatchPayload = (
  account: ResellerAccountPatchEntity
) => {
  return {
    name: account.name,
    currency: account.currency,
    phoneNumber: account.phoneNumber,
    address: {
      country: account.address.country,
      place_id: account.address.place_id,
      lat: account.address.lat,
      lng: account.address.lng,
      formatted_address: account.address.formatted_address,
    },
    ownerId: account.ownerId,
    lastOwnerId: account?.lastOwnerId!,
  }
}
